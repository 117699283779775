import {config} from '@loopia-group/services';

export default async (fileType, fileName) => {
  let newFile;

  if (fileType === 'icon') {
    const iconPath = config?.theme ? `${config.theme}/${fileName}` : fileName;

    try {
      newFile = await import(
        /* @vite-ignore */
        /* webpackChunkName: "icons" */
        /* webpackMode: "lazy-once" */
        '../icons/' + iconPath + '.svg'
      );
    } catch (e) {
      newFile = await import(
        /* @vite-ignore */
        /* webpackChunkName: "icons" */
        /* webpackMode: "lazy-once" */
        '../icons/' + fileName + '.svg'
      );
    }
  } else {
    newFile = await import(
      /* @vite-ignore */
      /* webpackChunkName: "issuers" */
      /* webpackMode: "lazy-once" */
      '../images/payment-cards/issuers/' + fileName + '.svg'
    );
  }

  return newFile.default;
};
